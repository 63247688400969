// import React from "react";

// const Menudata = (props) => {
//   return (
//     <li
//       className={
//         props.item.submenu && props.item.isActive
//           ? "active card-header card-header-parent"
//           : "card-header card-header-parent"
//       }
//       onClick={() => props.handlerOpenMenu(props.index)}
//     >
//       <a href="#" className=" menu-link">
//         {props.item.name}
//         {props.item.submenu && props.item.isActive ? (
//           <i className="icon-minus"></i>
//         ) : (
//           props.item.submenu && <i className="icon-plus"></i>
//         )}
//       </a>
//       {props.item.submenu && props.item.isActive && (
//         <ul className="submenu-list">
//           {props.item.submenu.map((item, i) => (
//             <li
//               className={
//                 item.submenu && item.isActive
//                   ? "active card-header card-header-child"
//                   : "card-header card-header-child"
//               }
//               onClick={(e) =>
//                 props.handlerOpenSubMenu(e, props.index, i, "submenu")
//               }
//             >
//               <a href="#" className="sub-menu-link">
//                 {item.name}

//                 {item.submenu && item.isActive ? (
//                   <i className="icon-minus"></i>
//                 ) : (
//                   item.submenu && <i className="icon-plus faded"></i>
//                 )}
//               </a>
//               {item.submenu && item.isActive && (
//                 <ul className="inner-submenu">
//                   {item.submenu.map((item) => (
//                     <li
//                       onClick={(e) => e.stopPropagation()}
//                       className="menu-accordion"
//                     >
//                       <a href="#">{item}</a>
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </li>
//           ))}
//         </ul>
//       )}
//     </li>
//   );
// };

// export default Menudata;


import { Link } from "gatsby";
import React, { useRef,useState,useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import ReapitBook from "../ReapitBook";

const Menudata = (props) => {
    const navigate = (e) => {
        e.stopPropagation()
        props.handlerOpenMenu(props.index)
    }
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }
        else {
        window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })}
    }, [])
    return (
        <li
            className={
                props.item.submenu || props.item.isActive
                ? "active card-header card-header-parent"
                : "card-header card-header-parent"
            }
            
        >
            <div className="w-100 position-relative a-link">
                <Link to={`${props.item.url}`}  onClick={props.hideMenu} className="menu-link">
                    <div className={props.active ? "active-menu" : ""}>{props.item.name}</div>
                </Link>
                {props.item.submenu && props.item.isActive ? (
                    <i className="icon-minus" onClick={navigate}></i>
                ) : (
                    props.item.submenu.length > 0 && <i className="icon-plus" onClick={navigate}></i>
                )}
            </div>
            {props.item.submenu && props.item.isActive && (
                <ul className="submenu-list">
                    {props.item.submenu.map((item, i) => (
                        <li
                            className={
                                item.submenu && item.isActive
                                ? "active card-header card-header-child"
                                : "card-header card-header-child"
                            }
                            key={i}
                            onClick={(e) => props.handlerOpenSubMenu(e, props.index, i, "submenu")}
                        >
                            <div className="w-100 position-relative a-link">
                                {item.url.includes("http") ?
                                    <a href={item.url} target="_blank" className="sub-menu-link">{item.name}</a> :
                                    item.url.includes("book-a-valuation") ? <>
                                    {renderComponent &&<ReapitBook className='cursor-pointer sub-menu-link' type="[&quot;sales&quot;,&quot;lettings&quot;]">
                                        {item.name}
                                    </ReapitBook>} </>:
                                    <Link to={item.url} onClick={props.hideMenu} className="sub-menu-link">{item.name}</Link>
                                }
                                {item.submenu.length > 0 && item.isActive ? (
                                    <i className="icon-minus"></i>
                                ) : (
                                    item.submenu.length > 0 && <i className="icon-plus"></i>
                                )}
                            </div>
                            {item.submenu.length > 0 && item.isActive && (
                                <ul className="submenu-list">
                                    {item.submenu.map((sub_item, k) => (
                                        <li onClick={(e) => e.stopPropagation()} className="menu-accordian" key={k}>
                                            <Link to={item.url} className="sub-menu-link">{sub_item.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default Menudata;
